/*
 * Copyright © Ergonode Sp. z o.o. All rights reserved.
 * See LICENSE for license details.
 */
<template>
    <div class="navigation-side-bar-logo">
         <img class="comet" src="@Core/components/Icons/Logo/logobg2.png">

    </div>

</template>

<script>

import {
    WHITE,
} from '@UI/assets/scss/_js-variables/colors.scss';

export default {
    name: 'NavigationSideBarLogo',
   
  
    computed: {
        whiteColor() {
            return WHITE;
        }, 
    },
};
</script>

<style lang="scss" scoped>
    .navigation-side-bar-logo {
        padding: 24px;
    }
    img.comet {
    width: 80%;
   }
</style>
